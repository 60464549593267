<script>
    /**
     * Dedicated vie to dates range picker
     * @author Evgeny Shevtsov, info@sitespring.ru
     * @homepage https://sitespring.ru
     * @licence Proprietary
     */
    import moment from "moment";
    import Formatter from "../../utils/Formatter";

    export default {
        name: "RangePicker",
        props: {
            value: {
                type: Array,
                default() {
                    return [];
                }
            }
        },
        data() {
            return {
                dateDisplayFormat: Formatter.dateDisplayFormat,
                dateSubmitFormat: Formatter.dateSubmitFormat,
                internalValue: this.value,
                pickerOptions: {
                    shortcuts: [
                        {
                            text: 'Неделя',
                            onClick(picker) {
                                const end = new Date();
                                const start = moment().subtract(1, "week").toDate();
                                picker.$emit('pick', [start, end]);
                            }
                        },
                        {
                            text: 'Месяц',
                            onClick(picker) {
                                const end = new Date();
                                const start = moment().subtract(1, "month").toDate();
                                picker.$emit('pick', [start, end]);
                            }
                        },
                        {
                            text: 'Квартал',
                            onClick(picker) {
                                const end = new Date();
                                const start = moment().subtract(1, "quarter").toDate();
                                picker.$emit('pick', [start, end]);
                            }
                        },
                        {
                            text: 'Год',
                            onClick(picker) {
                                const end = new Date();
                                const start = moment().subtract(1, "year").toDate();
                                picker.$emit('pick', [start, end]);
                            }
                        }
                    ]
                }
            }
        },
        watch: {
            internalValue() {
                this.$emit("input", this.internalValue);
            }
        }
    }
</script>


<template>
    <el-date-picker
            v-model="internalValue"
            type="daterange"
            :clearable="false"
            align="right"
            :format="dateDisplayFormat"
            :value-format="dateSubmitFormat"
            unlink-panels
            range-separator="-"
            start-placeholder="Дата начала"
            end-placeholder="Дата конца"
            :picker-options="pickerOptions">
    </el-date-picker>
</template>


<style lang="scss" scoped>
    .el-date-editor--daterange {
        width: auto;
    }
</style>