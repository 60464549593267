/**
 * Printer for report
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage https://sitespring.ru
 * @licence Proprietary
 */
import ExcelPrinter from "../../../utils/ExcelPrinter";
import Formatter from "../../../utils/Formatter";

export default class ReportDebtExcelPrinter extends ExcelPrinter {

    /**
     * @param fetchedData
     * */
    constructor(fetchedData) {
        super();
        this.fetchedData = fetchedData;
    }


    /**
     * @inheritDoc
     * */
    getData() {
        return this.fetchedData;
    }


    /**
     * @inheritDoc
     * */
    getTitle() {
        return `Отчет долги ${Formatter.asDate(this.date, "LL")}`;
    }


    /**
     * @inheritDoc
     * */
    getColumns() {
        return [
            {
                header: 'Контрагент', key: 'counterparty', width: 50, style: {
                    alignment: {horizontal: 'left'}
                }
            },
            {
                header: 'Долг, заказов', key: 'orders_count', width: 25, style: {
                    alignment: {horizontal: 'center'}
                }
            },
            {
                header: 'Долг, руб', key: 'debt_total', width: 25, style: {
                    alignment: {horizontal: 'right'}
                }
            },
        ]
    }


    /**
     * @inheritDoc
     * */
    applyDataRow(worksheet, dataRow) {
        let row = worksheet.addRow({
            counterparty: dataRow.counterparty_name,
            orders_count: Number.parseInt(dataRow.orders_count),
            debt_total: Number.parseFloat(dataRow.debt_total)
        });
        row.alignment = {vertical: 'top', wrapText: false};
        row.getCell(1).alignment = {horizontal: 'left'};
        row.getCell(2).alignment = {horizontal: 'center'};
        row.getCell(3).alignment = {horizontal: 'right'};
    }
}