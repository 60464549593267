<script>
    /**
     * @author Evgeny Shevtsov, info@sitespring.ru
     * @homepage https://sitespring.ru
     * @licence Proprietary
     */

    import FiltersToolbar from "./FiltersToolbar";
    import DataGrid from "./DataGrid";
    import ReportModel from "./ReportModel";
    import each from "lodash/each";
    import keys from "lodash/keys";

    export default {
        name: "Main",
        components: {DataGrid, FiltersToolbar},
        data() {
            return {
                model: new ReportModel(),
                fetchedData: [],
                detailsData: {},
                expandRowKeys: []
            }
        },
        watch: {
            // watch keys and auto expand details
            expandRowKeys(counterpartyIDs) {
                if (counterpartyIDs && counterpartyIDs.length) {
                    each(counterpartyIDs, this.expandDetails);
                }
            }
        },
        methods: {
            async populateState() {
                let data = window.history.state;
                try {
                    // set data if persist
                    if (data) {
                        this.model.set(data);
                        // wait while fetched data
                        await this.fetchData();
                        // when data has fetched expand the keys
                        if (data.expandRowKeys) {
                            this.expandRowKeys = data.expandRowKeys;
                        }
                    }
                } catch (e) {
                    console.warn("Failed to decode debt data", e);
                }
            },

            saveState(extraData = {}) {
                try {
                    let storedData = {
                        ...this.model.attributes,
                        ...extraData
                    };
                    // replace associated data in history to provide "back" data handling
                    window.history.replaceState(storedData, window.document.title);
                } catch (e) {
                    console.warn(e);
                }
            },

            onExpandChange(row) {
                this.expandDetails(row.counterparty_id);
                // save all expanded keys
                this.saveState({
                    expandRowKeys: [
                        // ...keys(this.detailsData),
                        row.counterparty_id
                    ]
                });
            },

            async expandDetails(counterparty_id) {
                // do not request if already has data
                if (this.detailsData[counterparty_id]) {
                    return;
                }
                // make reactive
                this.detailsData = {
                    ...this.detailsData,
                    [counterparty_id]: await this.model.fetchDetails(counterparty_id)
                };
            },

            //when filters submit click
            async onFiltersSubmit() {
                await this.fetchData();
                // save state after new data has fetched
                this.saveState();
                // reset previous populated details data
                this.detailsData = {};
                this.expandRowKeys = [];
            },

            // fetch new data
            async fetchData() {
                try {
                    this.fetchedData = await this.model.fetchData();
                } catch (e) {
                    console.error(e);
                    this.$message.error("Ошибка запроса");
                }
            }
        },

        created() {
            this.populateState();
        }
    }
</script>


<template>
    <div v-loading="model.loading">
        <div class="humanize-container--long">
            <filters-toolbar :filters="model.attributes"
                             :fetched-data="fetchedData"
                             @submit="onFiltersSubmit"></filters-toolbar>
        </div>
        <data-grid :fetched-data="fetchedData"
                   :details-data="detailsData"
                   row-key="counterparty_id"
                   :expand-row-keys="expandRowKeys"
                   @expand-change="onExpandChange"></data-grid>
    </div>
</template>


<style scoped>

</style>