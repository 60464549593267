<script>
import OrderPayment from "@/models/OrderPayment";

/**
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage https://sitespring.ru
 * @licence Proprietary
 */


export default {
    name: "PaymentTypesPicker",
    props: {
        value: Array,
        optionsFetcher: {
            type: Function,
            default: OrderPayment.getTypes
        }
    },
    data() {
        return {
            internalValue: this.value,
            loading: false,
            options: []
        }
    },
    watch: {
        // update inner value when prop changed
        value: function () {
            this.internalValue = this.value;
        },
        internalValue: function () {
            this.$emit("input", this.internalValue);
        }
    },
    methods: {
        async fetchData() {
            this.loading = true;
            try {
                this.options = await this.optionsFetcher();
            } catch (e) {
                this.$message.error("Ошибка получения статусов оплаты");
            } finally {
                this.loading = false;
            }
        },
        onVisibleChange(visible) {
            // autoload on expand options
            if (visible && !this.options.length) {
                this.fetchData();
            }
        }
    },
    created() {
        // Autoload on value
        if (this.value.length) {
            this.fetchData();
        }
    }
}
</script>


<template>
    <el-select
        v-model="internalValue"
        multiple
        clearable
        :loading="loading"
        @visible-change="onVisibleChange"
        v-bind="$attrs">
        <el-option
            v-for="item in options"
            :key="item.type"
            :label="item.typeText"
            :value="item.type">
        </el-option>
    </el-select>
</template>


<style scoped>

</style>