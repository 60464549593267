/**
 * Model to manage data
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage https://sitespring.ru
 * @licence Proprietary
 */
import {Model} from "vue-mc";
import Formatter from "../../../utils/Formatter";
import Vue from "vue";
import OrderPayment from "@/models/OrderPayment";
import {isEqual} from "lodash";

export default class ReportModel extends Model {
    /**
     * Способы выборки
     * */
    static SELECTION_TYPE_BY_CREATED_AT = 'created_at';
    static SELECTION_TYPE_BY_DELIVERY_DATE = 'delivery_date';


    /**
     * Карта способов выборки
     * @return {Object.<String,String>}
     * */
    static selectionTypes() {
        return {
            [this.SELECTION_TYPE_BY_CREATED_AT]: 'Выборка по дате внесения платежа',
            [this.SELECTION_TYPE_BY_DELIVERY_DATE]: 'Выборка по дате доставки заказа',
        };
    }

    /**
    * @type {String} Название текущего способа выборки
    * */
    get selectionTypeText() {
        return this.constructor.selectionTypes()[this.selectionType];
    }

    /**
     * @type {String} Название поля группировки в результатах отчета
     * */
    get selectionFieldName() {
        switch (this.selectionType) {
            case this.constructor.SELECTION_TYPE_BY_DELIVERY_DATE:
                return 'delivery_date';
            case this.constructor.SELECTION_TYPE_BY_CREATED_AT:
            default:
                return 'created_at';
        }
    }


    defaults() {
        return {
            counterpartyIds: [],
            counterpartyGroupIds: [],
            types: [],
            statuses: [],
            payStatuses: [],
            dates: [new Date(), new Date()],
            fetchedData: [],
            selectionType: this.constructor.SELECTION_TYPE_BY_CREATED_AT
        }
    }

    routes() {
        return {
            'data': 'admin/report/payment',
        }
    }


    /**
     * Populate to be send
     * */
    getSubmitData() {
        return {
            selectionType: this.selectionType,
            date_from: Formatter.toSubmitDate(this.dates[0]),
            date_till: Formatter.toSubmitDate(this.dates[1]),
            ...(this.counterpartyIds ? {counterpartyIds: this.counterpartyIds} : {}),
            ...(this.counterpartyGroupIds ? {counterpartyGroupIds: this.counterpartyGroupIds} : {}),
            ...(this.statuses ? {statuses: this.statuses} : {}),
            ...(this.payStatuses ? {payStatuses: this.payStatuses} : {}),
            ...(this.types ? {types: this.types} : {})
        }
    }


    /**
     * Send request to fetch data from remote
     * */
    async fetchData(extraParams = {}) {
        const params = this.getSubmitData();
        // Handle caching the same requests
        if (isEqual(this._lastFetchQueryParams, params)) {
            return false;
        } else {
            this._lastFetchQueryParams = params;
        }

        const method = 'GET';
        const url = this.getURL(this.getRoute('data'));
        return this.request({...extraParams, method, url, params}, this.onFetch, this.onFetchSuccess, this.onFetchFailure);
    }


    /**
     * Rewrite super to set fetched data instead of attributes
     * */
    onFetchSuccess(response) {
        let data = response.getData(); // A fetch request must receive *some* data in return.
        this.set("fetchedData", data);
        this.sync();

        Vue.set(this, 'fatal', false);
        Vue.set(this, 'loading', false);
        this.emit('fetch', {
            data,
            error: null
        });
    }


    /**
     * Fetch pay types and cache data in instance context
     * */
    fetchPayTypes() {
        if (!this._cachedPayTypes) {
            return OrderPayment.getTypes().then(data => this._cachedPayTypes = data);
        }
        return new Promise((resolve) => resolve(this._cachedPayTypes));
    }
}