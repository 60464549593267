<script>
    /**
     * @author Evgeny Shevtsov, info@sitespring.ru
     * @homepage https://sitespring.ru
     * @licence Proprietary
     */
    import FiltersToolbar from "./FiltersToolbar";
    import DataGrid from "./DataGrid";
    import ReportProduct from "./ReportProduct";

    export default {
        name: "Main",
        components: {DataGrid, FiltersToolbar},
        data() {
            return {
                model: new ReportProduct()
            }
        }
    }
</script>


<template>
    <div v-loading="model.loading">
        <div class="humanize-container--long">
            <filters-toolbar :model="model"></filters-toolbar>
        </div>
        <data-grid :model="model"></data-grid>
    </div>
</template>


<style scoped>

</style>