<script>
    /**
     * @author Evgeny Shevtsov, info@sitespring.ru
     * @homepage https://sitespring.ru
     * @licence Proprietary
     */
    import Formatter from "../../../utils/Formatter";
    import sumBy from "lodash/sumBy";

    export default {
        name: "DataGrid",
        props: {
            fetchedData: {
                type: Array,
                required: true
            },
            detailsData: {
                type: Object,
                default() {
                    return {};
                }
            }
        },
        computed: {
            totalOrdersSummary: vm => sumBy(vm.fetchedData, v => parseInt(v.orders_count)),
            totalDebtSummary: vm => sumBy(vm.fetchedData, v => parseInt(v.debt_total))
        },
        methods: {
            priceFormatter(row, column, cellValue, index) {
                return Formatter.asCurrency(cellValue);
            }
        }
    }
</script>


<template>
    <el-table v-bind="$attrs"
              v-on="$listeners"
              :data="fetchedData"
              border>
        <el-table-column width="50" type="expand">
            <template v-slot:default="{row}">
                <ul v-if="detailsData[row.counterparty_id]"
                    class="order-list">
                    <li v-for="(item, index) in detailsData[row.counterparty_id]" :key="index">
                        <router-link :to="{name:'order-basic',params:{id:item.id}}">#{{item.number}}</router-link>
                        , {{item.debt_total|currency}}
                    </li>
                </ul>
            </template>
        </el-table-column>
        <el-table-column
                prop="counterparty_name"
                min-width="300"
                label="Контрагент">
        </el-table-column>
        <el-table-column
                prop="orders_count"
                label="Всего долг, заказов"
                align="center">
            <template v-slot:default="{row}">
                {{row.orders_count}}
            </template>
        </el-table-column>
        <el-table-column
                label="Всего долг, руб"
                prop="debt_total"
                align="center"
                :formatter="priceFormatter">
        </el-table-column>
        <template v-slot:append>
            <div class="summary"
                 v-if="totalOrdersSummary > 0">
                Общая сумма долга: <strong>{{totalDebtSummary|currency}}</strong> по <strong>{{totalOrdersSummary}}</strong> заказам.
            </div>
        </template>
    </el-table>
</template>


<style scoped lang="scss">
    .summary {
        padding: 1rem;
        font-size: 1.2rem;
    }

    .order-list {
        padding: 0;
        margin: 0;
        list-style-type: none;

        li {
            margin: .5rem 0;
        }
    }
</style>