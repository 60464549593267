/**
 * Printer for report
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage https://sitespring.ru
 * @licence Proprietary
 */
import ExcelPrinter from "../../../utils/ExcelPrinter";
import Formatter from "../../../utils/Formatter";
import {find, sumBy} from "lodash";

export default class ReportPaymentExcelPrinter extends ExcelPrinter {

    /**
     * @param fetchedData
     * @param payTypes
     * */
    constructor(fetchedData, payTypes) {
        super();
        this.fetchedData = fetchedData;
        this.payTypes = payTypes;
    }


    /**
     * @inheritDoc
     * */
    getData() {
        return this.fetchedData;
    }


    /**
     * @inheritDoc
     * */
    getTitle() {
        return `Отчет касса ${Formatter.asDate(this.date, "LL")}`;
    }


    /**
     * @inheritDoc
     * */
    getColumns() {
        return [
            {
                header: 'Дата', key: 'created_at', width: 50, style: {
                    alignment: {horizontal: 'left'}
                }
            },
            {
                header: 'Способ оплаты', key: 'type', width: 25, style: {
                    alignment: {horizontal: 'center'}
                }
            },
            {
                header: 'Платеж, руб',
                key: 'value',
                width: 25,
                style: {
                    alignment: {horizontal: 'right'},
                    // Добавляем форматирование чисел в колонку чтобы они автоматически суммировались экселем
                    // @see https://www.npmjs.com/package/exceljs#number-value
                    numFmt: '#,##0.00'
                }
            },
        ]
    }


    /**
     * @inheritDoc
     * */
    applyDataRow(worksheet, dataRow) {
        const row = worksheet.addRow({
            type: this.getPayTypeText(dataRow.type),
            created_at: Formatter.asDateTime(dataRow.created_at),
            value: Number.parseFloat(dataRow.value) //Formatter.asPrice(dataRow.value)
        });
        row.alignment = {vertical: 'top', wrapText: false};
        row.getCell(1).alignment = {horizontal: 'left'};
        row.getCell(2).alignment = {horizontal: 'center'};
        row.getCell(3).alignment = {horizontal: 'right'};

        // refund case
        if (dataRow.value < 0) {
            row.font = {color: {argb: "00cc0000"}} // red color
        }
    }


    getTotals() {
        const summary = Formatter.asCurrency(sumBy(this.fetchedData, i => parseFloat(i.value)));
        return `Итого платежей на общую сумму ${summary}`
    }

    applyTotalRow(worksheet, summary) {
        const row = worksheet.addRow();
        worksheet.mergeCells(`A${worksheet.rowCount}:C${worksheet.rowCount}`);
        row.font = {bold: true, size: 12};
        row.getCell(1).value = summary
    }

    getPayTypeText(type) {
        const objectForType = find(this.payTypes, {type});
        return objectForType && objectForType.typeText;
    }
}