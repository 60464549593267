<script>
/**
 * Remote select counterparty`s group
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage http://sitespring.ru
 * @licence Proprietary
 */
import BaseStore from "../models/BaseStore";

export default {
  name: "CounterpartyGroupPicker",
  props: {
    value: Number | String,
    // limit the pagination, 0 - no limit
    limit: {
      type: Number,
      default: 5
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      innerValue: this.value,
      options: [],
      store: new BaseStore([], {
        entityName: 'counterparty-group',
        autoLoad: true,
        fetchParams: {
          limit: this.limit,
          sort: '-name',
        }
      })
    }
  },
  watch: {
    innerValue: function (val) {
      this.$emit('input', val);
    }
  },
  methods: {
    setValue(val) {
      this.store.applyFilter('id', {
        property: 'id',
        value: val
      });
      this.store.fetch().then(() => this.innerValue = val);
    },
    fetchOptions(query) {
      query = query.trim();
      //prevent from auto fetching with empty query
      if (query === '') {
        return;
      }
      // remove filters
      this.store.dropFilters();
      this.store.setOption("fetchParams", {
        ...this.store.getOption("fetchParams"),
        filterText: query
      });
      this.store.fetch();
    },
    getMetaString(model) {
      let str = '';
      if (model.notes) {
        str += `<br><i class="text-notes"></i> ${model.notes}`;
      }
      return str;
    },
    onChange(val) {
      // if select the model, emit the parent to do actions
      if (val) {
        this.$emit('modelselect', this.store.find({id: val}));
      }
    }
  },
  created() {
    if (this.innerValue) {
      this.setValue(this.innerValue);
    }
  }
}
</script>


<template>
  <el-select v-model="innerValue"
             remote
             clearable
             filterable
             :multiple="multiple"
             @change="onChange"
             :loading="store.loading"
             :remote-method="fetchOptions"
             placeholder="Название..."
             v-bind="$attrs">

    <template slot="prefix">
      <i class="el-input__icon el-icon-magic-stick"></i>
    </template>
    <el-option v-for="option in store.models"
               :label="option.name"
               :value="option.id"
               :key="option.id">
      <div class="name">{{ option.name }}</div>
      <div class="metas" v-html="getMetaString(option)"></div>

    </el-option>
  </el-select>
</template>


<style scoped lang="scss">
.el-select-dropdown {
  &__item {
    height: auto;

    .name {
      line-height: 20px;
    }

    .metas {
      line-height: 12px;
      font-size: 12px;
      color: silver;
      font-weight: 300;
    }
  }
}
</style>