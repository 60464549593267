<script>
/**
 * @author Evgeny Shevtsov <info@sitespring.ru>
 * @homepage https://sitespring.ru
 * @licence Proprietary, Commercial
 */
import ChartBar from "@/components/ChartBar";
import ReportModel from "@/views/report/payment/ReportModel";
import {groupBy, map, sumBy, find} from "lodash";
import Formatter from "@/utils/Formatter";

export default {
    name: 'DataChart',
    components: {ChartBar},
    props: {
        model: {
            type: ReportModel
        }
    },
    data() {
        return {
            payTypes: [],
            chartData: {},
            options: {
                maintainAspectRatio: false,
                tooltips: {
                    callbacks: {
                        label(tooltipItem, data) {
                            const payTypeLabel = data.datasets[tooltipItem.datasetIndex].label;
                            return `${payTypeLabel} ${Formatter.asCurrency(tooltipItem.value)}`;
                        }
                    }
                }
            }
        }
    },
    methods: {
        async calculateChartData(data) {
            // ensure paytypes presents
            if (!this.payTypes.length) {
                this.payTypes = await this.model.fetchPayTypes();
            }

            const selectionFieldName = this.model.selectionFieldName;
            const groupedByDate = groupBy(data, item => this.$Formatter.asDate(item[selectionFieldName], "ll"));
            let labels = Object.keys(groupedByDate);
            let datasetsTypes = Object.keys(groupBy(data, 'type'));
            let datasets = datasetsTypes.map(type => {
                // let value = sumBy(groupedByDate, {type});
                const label = this.getPayTypeText(type);
                const data = map(groupedByDate, group => sumBy(group, item => item.type === type && parseFloat(item.value)));
                const backgroundColor = '#' + this.createRandomColor();
                return {label, data, backgroundColor};
            });

            this.chartData = {labels, datasets};
        },
        getPayTypeText(type) {
            const objectForType = find(this.payTypes, {type});
            return objectForType && objectForType.typeText;
        },
        createRandomColor() {
            return Math.floor(Math.random() * 16777215).toString(16);
        },
        onDataFetch({data}) {
            this.calculateChartData(data);
        }
    },
    created() {
        this.model.on('fetch', this.onDataFetch);
    }
}
</script>

<template>
    <div class="chart">
        <chart-bar :chartData="chartData" :options="options"></chart-bar>
    </div>
</template>
<style scoped lang="scss">
.chart {
    display: block;
    max-height: 50vh;
}
</style>