import { render, staticRenderFns } from "./PaymentTypesPicker.vue?vue&type=template&id=5d4051cb&scoped=true&"
import script from "./PaymentTypesPicker.vue?vue&type=script&lang=js&"
export * from "./PaymentTypesPicker.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d4051cb",
  null
  
)

export default component.exports