/**
 * Printer for report
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage https://sitespring.ru
 * @licence Proprietary
 */
import ExcelPrinter from "../../../utils/ExcelPrinter";
import Formatter from "../../../utils/Formatter";

export default class ReportProductExcelPrinter extends ExcelPrinter {

    /**
     * @param model ReportProduct The report`s data model
     * */
    constructor(model) {
        super();
        this.dataModel = model;
    }


    /**
     * @inheritDoc
     * */
    getData() {
        return this.dataModel.fetchedData;
    }


    /**
     * @inheritDoc
     * */
    getTitle() {
        return `Отчет продукты ${Formatter.asDate(this.date, "LL")}`;
    }


    /**
     * @inheritDoc
     * */
    getColumns() {
        return [
            {
                header: 'Продукт', key: 'product_name', width: 50, style: {
                    alignment: {horizontal: 'left'}
                }
            },
            {
                header: 'Цех', key: 'vendor_name', width: 25, style: {
                    alignment: {horizontal: 'center'}
                }
            },
            {
                header: 'Всего единиц', key: 'count', width: 17, style: {
                    alignment: {horizontal: 'center'}
                }
            },
            {
                header: 'Общий вес, кг', key: 'weight', width: 17, style: {
                    alignment: {horizontal: 'right'}
                }
            },
            {
                header: 'Всего на сумму, руб',
                key: 'cost',
                width: 20,
                style: {
                    alignment: {horizontal: 'right'},
                    // Добавляем форматирование чисел в колонку чтобы они автоматически суммировались экселем
                    // @see https://www.npmjs.com/package/exceljs#number-value
                    numFmt: '#,##0.00'
                }
            },
            {
                header: `Цена на ${Formatter.asDateTime()}`,
                key: 'actualPriceText',
                width: 20,
                style: {
                    alignment: {horizontal: 'right'},
                    numFmt: '#,##0.00'
                }
            },
            {
                header: `Ед. измерения`,
                key: 'measure',
                width: 20
            }
        ]
    }


    /**
     * @inheritDoc
     * */
    applyDataRow(worksheet, dataRow) {
        let row = worksheet.addRow({
            product_name: dataRow.product_name,
            vendor_name: dataRow.vendor_name,
            count: dataRow.total_count,
            weight: Formatter.asWeight(dataRow.total_weight_kg),
            cost: Number.parseFloat(Formatter.asDecimal(dataRow.total_cost, {useGrouping: false})),
            actualPriceText: Number.parseFloat(Formatter.asDecimal(dataRow.price_action || dataRow.price)),
            measure: `${dataRow.measureText}`
        });
        row.alignment = {vertical: 'top', wrapText: false};
        row.getCell(1).alignment = {horizontal: 'left'};
        row.getCell(2).alignment = {horizontal: 'center'};
        row.getCell(3).alignment = {horizontal: 'center'};
        row.getCell(4).alignment = {horizontal: 'right'};
        row.getCell(5).alignment = {horizontal: 'right'};
        row.getCell(6).alignment = {horizontal: 'right'};
        row.getCell(7).alignment = {horizontal: 'center'};
    }
}