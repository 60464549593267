<script>/**
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage https://sitespring.ru
 * @licence Proprietary
 */
import ReportProduct from "./ReportProduct";
import Formatter from "../../../utils/Formatter";
import compact from "lodash/compact";

export default {
  name: "DataGrid",
  props: {
    model: {
      type: ReportProduct,
      required: true
    }
  },
  data() {
    return {
      tableData: []
    }
  },
  methods: {
    productNameFormatter(row, column, cellValue, index) {
      return compact([row.code, row.product_name]).join(". ");
    },
    weightFormatter(row, column, cellValue, index) {
      return Formatter.asWeight(cellValue);
    },
    priceFormatter(row, column, cellValue, index) {
      return Formatter.asCurrency(cellValue);
    },
    getSummaries({columns, data}) {
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = 'Total Cost';
          return;
        }
        const values = data.map(item => Number(item[column.property]));
        if (!values.every(value => isNaN(value))) {
          sums[index] = '$ ' + values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
        } else {
          sums[index] = 'N/A';
        }
      });

      return sums;
    }
  }
}
</script>


<template>
  <el-table
      :data="model.fetchedData"
      highlight-current-row
      sum-text="Итого"
      border
      show-summary>
    <el-table-column
        prop="product_name"
        min-width="200"
        label="Продукт"
        :formatter="productNameFormatter">
    </el-table-column>
    <el-table-column
        prop="vendor_name"
        label="Цех"
        align="center">
    </el-table-column>
    <el-table-column
        label="Всего единиц"
        prop="total_count"
        align="center">
    </el-table-column>
    <el-table-column
        prop="total_weight_kg"
        label="Общий вес, кг"
        align="right"
        :formatter="weightFormatter">
    </el-table-column>
    <el-table-column
        prop="total_cost"
        label="Всего на сумму"
        align="right"
        :formatter="priceFormatter">
    </el-table-column>
    <el-table-column
        align="right">
      <template v-slot:header>Цена на
        <nobr>{{ $Formatter.asDate() }}</nobr>
      </template>
      <template v-slot:default="{ row, column, $index }">
        {{ $Formatter.asPrice(row.price_action || row.price) }}/{{row.measureText}}
      </template>
    </el-table-column>
  </el-table>
</template>


<style scoped>

</style>