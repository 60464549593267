<script>
/**
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage https://sitespring.ru
 * @licence Proprietary
 */
import RangePicker from "../RangePicker";
import CounterpartyPicker from "../../../components/CounterpartyPicker";
import Formatter from "../../../utils/Formatter";
import ReportModel from "./ReportModel";
import ReportPaymentExcelPrinter from "@/views/report/payment/ExcelPrinter";
import PaymentTypesPicker from "@/components/PaymentTypesPicker";
import CounterpartyGroupPicker from "@/components/CounterpartyGroupPicker";

export default {
    name: "FiltersToolbar",
    components: {PaymentTypesPicker, CounterpartyPicker, RangePicker, CounterpartyGroupPicker},
    props: {
        model: {
            type: ReportModel,
            required: true
        }
    },
    data() {
        return {
            isLoading: false
        }
    },
    methods: {
        onSubmitClick() {
            this.model.fetchData().catch(() => {
                this.$message.error("Ошибка запроса");
            });
        },
        async onExcelExportClick() {
            this.isLoading = true;
            const payTypes = await this.model.fetchPayTypes();
            const printer = new ReportPaymentExcelPrinter(this.model.fetchedData, payTypes);
            await printer.writeFile(`Ферма_Касса_${Formatter.asDateTime()}.xlsx`);
            this.isLoading = false;
        }
    }
}
</script>


<template>
    <el-form class="filters-toolbar" v-loading="isLoading">
        <div class="toolbar">
            <el-form-item label="Агрегатор платежей">
                <el-radio-group v-model="model.selectionType">
                    <el-radio v-for="(label,value) in model.constructor.selectionTypes()" :label="value">{{ label }}</el-radio>
                </el-radio-group>
            </el-form-item>
        </div>
        <div class="toolbar wrap">
            <el-form-item label="Период выборки">
                <range-picker v-model="model.dates" :style="{width:'250px'}"></range-picker>
            </el-form-item>
            <el-form-item label="Тип платежа" :style="{width:'250px'}">
                <payment-types-picker v-model="model.types"
                                      placeholder="Любой"
                                      :options-fetcher="model.fetchPayTypes"></payment-types-picker>
            </el-form-item>
        </div>
        <div class="toolbar">
            <el-form-item label="Контрагент">
                <counterparty-picker v-model="model.counterpartyIds"
                                     :style="{width:'300px'}"
                                     :limit="0"
                                     :multiple="true"></counterparty-picker>
            </el-form-item>
            <el-form-item label="Группа контрагентов">
                <counterparty-group-picker v-model="model.counterpartyGroupIds"
                                           :style="{width:'300px'}"
                                           :multiple="true"></counterparty-group-picker>
            </el-form-item>
        </div>


        <div class="buttons">
            <el-button type="info"
                       @click="onSubmitClick">Показать
            </el-button>
            <el-button icon="el-icon-download"
                       :disabled="!model.fetchedData.length"
                       @click="onExcelExportClick">Экспорт в excel
            </el-button>
        </div>
    </el-form>
</template>


<style lang="scss" scoped>
.filters-toolbar {
    margin: 0 0 20px;
    padding: 0 0 20px;
}

.toolbar {
    align-items: flex-end;
    margin: 0;

    .buttons {
        width: 100%;
        margin-left: 0;
    }
}

.el-form-item {
    margin-bottom: 10px;
}
</style>