<script>
    import BaseStore from "../models/BaseStore";
    import Product from "../models/Product";
    import compact from "lodash/compact";

    /**
     * @author Evgeny Shevtsov, info@sitespring.ru
     * @homepage https://sitespring.ru
     * @licence Proprietary
     */


    export default {
        name: "ProductPicker",
        props: {
            value: Number | String,
            // limit the pagination, 0 - no limit
            limit: {
                type: Number,
                default: 5
            },
            multiple: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                internalValue: this.value,
                options: [],
                store: new BaseStore([], {
                    model: Product,
                    fetchParams: {
                        sort: 'name',
                        fields: 'id,name,internal_code',
                        expand: 'vendorText',
                        filterText: ""
                    }
                }),
            }
        },
        watch: {
            internalValue: function () {
                this.$emit("input", this.internalValue);
            }
        },
        methods: {
            fetchOptions(query) {
                //prevent from auto fetching with empty query
                if (query === '') {
                    return;
                }
                
                // remove filters
                this.store.setOption("filters", []);
                this.store.setOption("fetchParams", {
                    ...this.store.getOption("fetchParams"),
                    filterText: query
                });
                this.store.fetch();
            },
            getMetaString(product) {
                return compact([
                    product.internal_code && `Артикул: ${product.internal_code}`,
                    // product.vendor_code && `Код производителя: ${product.vendor_code}`,
                    product.vendorText && `${product.vendorText}`,
                ]).join(", ");
            },
        }
    }
</script>


<template>
    <el-select v-model="internalValue"
               remote
               clearable
               filterable
               :multiple="multiple"
               :loading="store.loading"
               :remote-method="fetchOptions"
               placeholder="Название, артикул, код ..."
               v-bind="$attrs">
        <template slot="prefix">
            <i class="el-input__icon el-icon-magic-stick"></i>
        </template>
        <el-option v-for="option in store.models"
                   :label="option.name"
                   :value="option.id"
                   :key="option.id">
            <div class="name">{{option.name}}</div>
            <div class="metas" v-html="getMetaString(option)"></div>

        </el-option>
    </el-select>
</template>


<style lang="scss" scoped>
    .el-select-dropdown {
        &__item {
            height: auto;
            padding: 5px 20px;

            .name {
                line-height: 20px;
            }

            .metas {
                line-height: 12px;
                font-size: 12px;
                color: silver;
                font-weight: 300;
            }
        }
    }
</style>