/**
 * Model to manage data
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage https://sitespring.ru
 * @licence Proprietary
 */
import {Model} from "vue-mc";
import Formatter from "../../../utils/Formatter";
import Vue from "vue";

export default class ReportProduct extends Model {
    defaults() {
        return {
            counterpartyIds: [],
            counterpartyGroupIds: [],
            vendorIds: [],
            productIds: [],
            statuses: [],
            payStatuses: [],
            dates: [new Date(), new Date()],
            fetchedData: []
        }
    }

    routes() {
        return {
            'data': 'admin/report/product'
        }
    }


    /**
     * Populate to be send
     * */
    getSubmitData() {
        return {
            date_from: Formatter.toSubmitDate(this.dates[0]),
            date_till: Formatter.toSubmitDate(this.dates[1]),
            ...(this.counterpartyIds ? {counterpartyIds: this.counterpartyIds} : {}),
            ...(this.counterpartyGroupIds ? {counterpartyGroupIds: this.counterpartyGroupIds} : {}),
            ...(this.vendorIds ? {vendorIds: this.vendorIds} : {}),
            ...(this.productIds ? {productIds: this.productIds} : {}),
            ...(this.statuses ? {statuses: this.statuses} : {}),
            ...(this.payStatuses ? {payStatuses: this.payStatuses} : {})
        }
    }


    /**
     * Send request to fetch data from remote
     * */
    async fetchData(extraParams = {}) {
        let method = 'GET';
        let url = this.getURL(this.getRoute('data'));
        let params = this.getSubmitData();

        return this.request({...extraParams, method, url, params}, this.onFetch, this.onFetchSuccess, this.onFetchFailure);
    }


    /**
     * Rewrite super to set fetched data instead of attributes
     * */
    onFetchSuccess(response) {
        let data = response.getData().data; // A fetch request must receive *some* data in return.
        this.set("fetchedData", data);
        this.sync();
        Vue.set(this, 'fatal', false);
        Vue.set(this, 'loading', false);
        this.emit('fetch', {
            error: null
        });
    }
}