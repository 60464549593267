<script>
/**
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage https://sitespring.ru
 * @licence Proprietary
 */
import RangePicker from "../RangePicker";
import OrderStatusPicker from "../../order/StatusPicker";
import CounterpartyPicker from "../../../components/CounterpartyPicker";
import ReportDebtExcelPrinter from "./ExcelPrinter";
import Formatter from "../../../utils/Formatter";
import CounterpartyGroupPicker from "@/components/CounterpartyGroupPicker";

export default {
  name: "FiltersToolbar",
  components: {CounterpartyGroupPicker, CounterpartyPicker, OrderStatusPicker, RangePicker},
  props: {
    filters: {
      type: Object,
      required: true
    },
    fetchedData: {
      type: Array | Object,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      isLoading: false
    }
  },
  methods: {
    onSubmitClick() {
      this.$emit('submit');
    },
    async onExcelExportClick() {
      this.isLoading = true;
      let printer = new ReportDebtExcelPrinter(this.fetchedData);
      await printer.writeFile(`Отчет_долги_${Formatter.asDateTime()}.xlsx`);
      this.isLoading = false;
    }
  }
}
</script>


<template>
  <el-form class="filters-toolbar" v-loading="isLoading">
    <div class="toolbar wrap">
      <el-form-item label="Дата доставки заказа">
        <range-picker v-model="filters.dates" :style="{width:'250px'}"></range-picker>
      </el-form-item>
      <el-form-item label="Статус заказа" :style="{width:'250px'}">
        <order-status-picker v-model="filters.statuses"></order-status-picker>
      </el-form-item>
    </div>
    <div class="toolbar">
      <el-form-item label="Контрагент">
        <counterparty-picker v-model="filters.counterpartyIds"
                             :style="{width:'300px'}"
                             :limit="0"
                             :multiple="true"></counterparty-picker>
      </el-form-item>
      <el-form-item label="Группа контрагентов">
        <counterparty-group-picker v-model="filters.counterpartyGroupIds"
                                   :style="{width:'300px'}"
                                   :multiple="true"></counterparty-group-picker>
      </el-form-item>
    </div>
    <div class="buttons">
      <el-button type="info"
                 @click="onSubmitClick">Показать
      </el-button>
      <el-button icon="el-icon-download"
                 :disabled="!fetchedData.length"
                 @click="onExcelExportClick">Экспорт в excel
      </el-button>
    </div>
  </el-form>
</template>


<style lang="scss" scoped>
.filters-toolbar {
  margin: 0 0 20px;
  padding: 0 0 20px;
}

.toolbar {
  align-items: flex-end;
  margin: 0;

  .buttons {
    width: 100%;
    margin-left: 0;
  }
}

.el-form-item {
  margin-bottom: 10px;
}
</style>