<script>
/**
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage https://sitespring.ru
 * @licence Proprietary
 */

import FiltersToolbar from "./FiltersToolbar";
import DataGrid from "./DataGrid";
import ReportModel from "./ReportModel";
import DataChart from "@/views/report/payment/DataChart";

export default {
  name: "Main",
  components: {DataChart, DataGrid, FiltersToolbar},
  data() {
    return {
      model: new ReportModel()
    }
  },

}
</script>

<template>
  <div v-loading="model.loading">
    <div class="humanize-container--long">
      <filters-toolbar :model="model"></filters-toolbar>
    </div>
    <data-chart :model="model"></data-chart>
  </div>
</template>


<style scoped>

</style>