<script>
/**
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage https://sitespring.ru
 * @licence Proprietary
 */
import RangePicker from "../RangePicker";
import ReportProduct from "./ReportProduct";
import OrderStatusPicker from "../../order/StatusPicker";
import OrderPayStatusPicker from "../../order/PayStatusPicker";
import CounterpartyPicker from "../../../components/CounterpartyPicker";
import ProductPicker from "../../../components/ProductPicker";
import VendorPicker from "../../../components/VendorPicker";
import ExcelPrinter from "./ExcelPrinter";
import Formatter from "../../../utils/Formatter";
import CounterpartyGroupPicker from "@/components/CounterpartyGroupPicker";

export default {
  name: "FiltersToolbar",
  components: {
    CounterpartyGroupPicker,
    VendorPicker,
    ProductPicker,
    CounterpartyPicker,
    OrderStatusPicker,
    OrderPayStatusPicker,
    RangePicker
  },
  props: {
    model: {
      type: ReportProduct,
      required: true
    }
  },
  data() {
    return {
      isLoading: false
    }
  },
  methods: {
    onSubmitClick() {
      this.model.fetchData().catch(() => {
        this.$message.error("Ошибка запроса");
      });
    },
    async onExcelExportClick() {
      this.isLoading = true;
      let printer = new ExcelPrinter(this.model);
      await printer.writeFile(`Отчет_продукты_${Formatter.asDateTime()}.xlsx`);
      this.isLoading = false;
    }
  }
}
</script>


<template>
  <el-form class="filters-toolbar" v-loading="isLoading">
    <div class="toolbar wrap">
      <el-form-item label="Дата доставки заказа">
        <range-picker v-model="model.dates" :style="{width:'250px'}"></range-picker>
      </el-form-item>
      <el-form-item label="Контрагент">
        <counterparty-picker v-model="model.counterpartyIds"
                             :style="{width:'300px'}"
                             :limit="0"
                             :multiple="true"></counterparty-picker>
      </el-form-item>
      <el-form-item label="Группа контрагентов">
        <counterparty-group-picker v-model="model.counterpartyGroupIds"
                                   :style="{width:'300px'}"
                                   :multiple="true"></counterparty-group-picker>
      </el-form-item>
    </div>
    <div class="toolbar">
      <el-form-item label="Статус заказа" :style="{width:'250px'}">
        <order-status-picker v-model="model.statuses"></order-status-picker>
      </el-form-item>
      <el-form-item label="Статус оплаты" :style="{width:'250px'}">
        <order-pay-status-picker v-model="model.payStatuses"></order-pay-status-picker>
      </el-form-item>
    </div>
    <div class="toolbar">
      <el-form-item label="Продукция">
        <product-picker v-model="model.productIds"
                        :style="{width:'400px'}"
                        :multiple="true"></product-picker>
      </el-form-item>
      <el-form-item label="Цех">
        <vendor-picker v-model="model.vendorIds"
                       :style="{width:'200px'}"
                       :multiple="true"></vendor-picker>
      </el-form-item>
    </div>
    <div class="buttons">
      <el-button type="info"
                 @click="onSubmitClick">Показать
      </el-button>
      <el-button icon="el-icon-download"
                 :disabled="!model.fetchedData.length"
                 @click="onExcelExportClick">Экспорт в excel
      </el-button>
    </div>
  </el-form>
</template>


<style lang="scss" scoped>
.filters-toolbar {
  margin: 0 0 20px;
  padding: 0 0 20px;
}

.toolbar {
  align-items: flex-end;
  margin: 0;

  .buttons {
    width: 100%;
    margin-left: 0;
  }
}

.el-form-item {
  margin-bottom: 10px;
}
</style>