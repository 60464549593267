/**
 * Model to manage data
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage https://sitespring.ru
 * @licence Proprietary
 */
import {Model} from "vue-mc";
import Formatter from "../../../utils/Formatter";
import Vue from "vue";

export default class ReportModel extends Model {
    defaults() {
        return {
            counterpartyIds: [],
            counterpartyGroupIds: [],
            statuses: [],
            dates: [new Date(), new Date()]
        }
    }

    routes() {
        return {
            'data': 'admin/report/debt',
            'details': 'admin/report/debt-details'
        }
    }


    /**
     * Populate to be send
     * */
    getSubmitData() {
        return {
            date_from: Formatter.toSubmitDate(this.dates[0]),
            date_till: Formatter.toSubmitDate(this.dates[1]),
            ...(this.counterpartyIds ? {counterpartyIds: this.counterpartyIds} : {}),
            ...(this.counterpartyGroupIds ? {counterpartyGroupIds: this.counterpartyGroupIds} : {}),
            ...(this.statuses ? {statuses: this.statuses} : {})
        }
    }


    /**
     * Send request to fetch data from remote
     * */
    async fetchData(extraParams = {}) {
        let method = 'GET';
        let url = this.getURL(this.getRoute('data'));
        let params = this.getSubmitData();
        let response = await this.request({...extraParams, method, url, params}, this.onFetch, this.onFetchSuccess, this.onFetchFailure);

        return response.getData().data;
    }


    /**
     * Rewrite super to set fetched data instead of attributes
     * */
    onFetchSuccess(response) {
        Vue.set(this, 'fatal', false);
        Vue.set(this, 'loading', false);
        this.emit('fetch', {
            error: null
        });
    }


    /**
     * Send request to fetch details by counterparty
     * */
    async fetchDetails(counterparty_id, extraParams = {}) {
        let method = 'GET';
        let url = this.getURL(this.getRoute('details'));
        let params = {
            ...this.getSubmitData(),
            counterparty_id
        };
        let response = await this.request({...extraParams, method, url, params}, this.onFetch, this.onFetchSuccess, this.onFetchFailure);

        return response.getData().data;
    }
}